<template>
	<label for="privacy" class="privacy">
		<input type="checkbox" id="privacy" checked @change="privacyChange">
		<span class="privacy__checkbox"></span>
		<p class="privacy__text">C <a href="#" target="_blank">политикой конфиденциальности</a> ознакомлен(а)</p>
	</label>
</template>

<script>
export default {
	data(){
		return{
			isChecked: true
		}
	},

	methods:{
		privacyChange(){
			this.isChecked = !this.isChecked;
			this.$emit('check', this.isChecked);
		}
	}
}
</script>

<style lang="sass" scoped>
@import '../../sass/_variables'

.privacy
	display: flex
	align-items: center
	cursor: pointer

	&__text
		margin-left: 0.5rem
		font-size: 0.8rem

		& a
			color: $font-color-light-green
			text-decoration: none 

	& input
		transform: translateX(-10000%)
		position: absolute
		opacity: 0

	& input:focus + .privacy__checkbox
		box-shadow: 0 0 0.5em hsl(0deg 0% 48% / 80%)
	
	& input:checked + .privacy__checkbox
		background-color: $color-quiz-primary

	&__checkbox
		transition: all 0.3s
		height: 1rem
		width: 1rem
		background-color: #ccc
		border-radius: 4px
		position: relative

		&::after
			content: url('../../assets/img/checkPrivacy.svg')
			transition: all 0.3s
			opacity: 0
			position: absolute
			height: 100%
			width: 100%
			left: 0
			top: 0
			transform: scale(0.9) translateY(-22%)
		
	& input:checked + .privacy__checkbox::after
		opacity: 1

</style>