<template>
   <div class="user-name">
      <p class="user-name__title">{{ $t('Registration.Name.title') }}</p>
      <div class="user-name__box">
         <form @submit.prevent action="submit" class="user-name__form">
            <InputName @userInput="changeNameValue" @isValid="changeIsValid" />
            <SecondBtn
               :additionalType="'submit'"
               @clickBtn="submit"
               :isDisabled="!isNameValid || !isPrivacyChecked"
               :additionalStyles="'width: 100%; justify-content: center; margin-bottom: 2rem'"
               >{{ $t('Registration.Name.btn') }}</SecondBtn
            >
         </form>

         <div class="user-name__privacy">
            <Privacy @check="privacyChange" />
         </div>
      </div>
   </div>
</template>

<script>
import SecondBtn from '../components/UI/SecondBtn.vue';
import Privacy from '../components/UI/Privacy.vue';
import InputName from '../components/UI/InputName.vue';

import mutations from '../store/mutation-types';
export default {
   components: {
      SecondBtn,
      Privacy,
      InputName,
   },

   data() {
      return {
         nameValue: '',
         isNameValid: false,
         isPrivacyChecked: true,
      };
   },

   methods: {
      privacyChange(data) {
         this.isPrivacyChecked = data;
      },

      submit() {
         if (this.isNameValid && this.isPrivacyChecked) {
            this.$store.commit(`quiz/${mutations.CHANGE_NAME}`, this.nameValue);
            this.$router.push({ name: 'register' });
         }
      },

      changeNameValue(data) {
         this.nameValue = data;
      },

      changeIsValid(data) {
         this.isNameValid = data;
      },
   },
};
</script>

<style lang="sass" scoped>
@import '../sass/_variables'

@keyframes fade
   0%
      opacity: 0
      transform: translateX(100%) translateY(-50%)
   50%
      opacity: 1
   100%
      transform: translateX(-50%) translateY(-50%)

.user-name
   animation: fade 1s
   position: absolute
   top: 50%
   left: 50%
   transform: translate(-50%, -50%)
   max-width: 500px
   width: 100%
   padding: 0 0.5rem

   &__box
      box-shadow: 0 9px 40px 0 rgba(#e8e8e8, 0.11)
      border: 1.2px solid #e8e8e8
      padding: 1.5rem 2rem

   &__title
      font-size: 2rem
      text-align: center
      font-weight: 500
      margin-bottom: 2rem

   &__form > *:nth-child(1)
      margin-bottom: 2rem

.error
   border: 1px solid red !important
</style>